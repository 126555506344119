import { Controller } from "stimulus";
import moment from "moment-timezone";

export default class extends Controller {
  connect() {
    const timezoneName = moment.tz.guess();
    const browserOffsetMinutes = new Date().getTimezoneOffset();
    const browserOffsetHours = Math.abs(browserOffsetMinutes / 60);
    const browserTimezoneOffset = `UTC${browserOffsetMinutes <= 0 ? '+' : '-'}${String(browserOffsetHours).padStart(2, '0')}:00`;

    let timezoneAbbreviation = moment.tz(timezoneName).format('z');

    const dstToStandardAbbreviation = {
      PDT: 'PST',
      MDT: 'MST',
      CDT: 'CST',
      EDT: 'EST',
      CEST: 'CET'
    };

    // Adjust the abbreviation if it is a DST abbreviation
    if (dstToStandardAbbreviation[timezoneAbbreviation]) {
      timezoneAbbreviation = dstToStandardAbbreviation[timezoneAbbreviation];
    }

    console.log("Timezone working....");
    console.log("Browser timezone name:", timezoneName);
    console.log("Browser timezone offset:", browserTimezoneOffset);
    console.log("Browser timezone abbreviation:", timezoneAbbreviation);

    const select = this.element;

    for (let i = 0; i < select.options.length; i++) {
      const optionValue = select.options[i].value;
      if (timezoneAbbreviation === 'PST' && timezoneName !== 'America/Los_Angeles') {
        if (optionValue.includes(browserTimezoneOffset)) {
          select.selectedIndex = i;
          break;
        }
      } else {
        if (optionValue.includes(browserTimezoneOffset) || optionValue.includes(timezoneName) || optionValue.includes(timezoneAbbreviation)) {
          select.selectedIndex = i;
          break;
        }
      }
    }
    select.slim.setSelected(select.value);
  }
}