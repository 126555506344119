/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Tailwind Pro
import "./tailwindpro/*"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

// Rails Action Text
import "trix"
import "@rails/actiontext"

// Import helpers
import "./helpers/dropzone"
import "./helpers/cropperjs"

// Start Rails UJS
Rails.start()
