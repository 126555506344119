import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['richText'];

  connect() {
    console.log("Connected to the comment controller");
  }

  validateContent(event) {
    const editorContent = this.richTextTarget.editor.getDocument().toString().trim();

    if (editorContent.length === 0) {
      event.preventDefault(); // Prevent form submission
      alert('Please include a reason for your decision regarding this profile.');
    }
  }
}
