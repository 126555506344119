import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
    static targets = ["field"]
    connect() {
        const slimSelectInstance = new SlimSelect({
            select: this.fieldTarget,
            settings: {
                closeOnSelect: !this.fieldTarget.hasAttribute('multiple'),
                showSearch: !this.fieldTarget.hasAttribute('data-slim-disablesearch'),
                alwaysOpen: false,
                allowDeselect: true,
                placeholderText: "",
                searchText: this.fieldTarget.hasAttribute('data-slim-noresult') ? this.customSearchText(this.fieldTarget.getAttribute('data-slim-noresult')) : "No Results"
            },
            events: this.fieldTarget.hasAttribute('data-slim-addable')
                ? {
                    addable: (value) => {
                        if (this.fieldTarget.hasAttribute('data-slim-type') && this.fieldTarget.getAttribute('data-slim-type') === 'email') {
                            // Validate email format
                            const isValidEmail = this.isValidEmailAddress(value);
                            if (!isValidEmail) {
                                alert('Invalid email address');
                                return false;
                            }
                        }
                        const exists = slimSelectInstance.getData().some(item => item.text.toLowerCase() === value.toLowerCase());
                        if (exists) {
                            return false;
                        }

                        return {
                            text: value,
                            value: value,
                        };
                    },
                }
                : null,
        });


        setTimeout(() => {
            //this.fieldTarget.setAttribute('required', 'required');
            this.fieldTarget.style.opacity = '0';
            this.fieldTarget.style.width = '0px';
            this.fieldTarget.style.height = '0px';
            this.fieldTarget.style.display = '';
            this.fieldTarget.style.margin = '0px';
            this.fieldTarget.style.padding = '0px';
            this.fieldTarget.style.position = 'absolute';
            this.fieldTarget.style.top = '70%'; 
            this.fieldTarget.style.left = '50%';
        }, 10);

        // Add a click event listener to the document
        document.addEventListener("mousedown", (event) => {
            this.handleClickOutside(event, this.fieldTarget); // Pass the fieldTarget to the event handler
        });
    }

    handleClickOutside(event, fieldTarget) {
        const parent_id = fieldTarget.slim.settings.id;
        if (!event.target.closest(`#${parent_id}`)) {
            fieldTarget.slim.close();
        }
    }

    customSearchText(label) {
        const buttonString = `<div>Click <div class="ss-addable" style="border: 0px; margin-left: 0px;"><svg viewBox="0 0 100 100"><path d="M50,10 L50,90 M10,50 L90,50"></path></svg></div> to ${label}</div>`;
        return buttonString;
    }

    isValidEmailAddress(email) {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
        console.error('Is email address valid?:', emailRegex.test(email));
        return emailRegex.test(email);
    }
}
