import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = ["fullNavbar", "minNavbar", "icon", "navbarSpan", "topNav"]

  connect() {
    this.setVisibility(this.getCookie("navbar_state"));
  }

  toggled(event) {
    const value = (this.getCookie("navbar_state") === "open") ? "closed" : "open";
    this.setVisibility(value);
    this.setCookie("navbar_state", value);
  }

  setVisibility(state) {
    const navbarState = (state === "open");
    try {
      if(navbarState) {
        this.minNavbarTarget.classList.add('hidden');
        this.fullNavbarTarget.classList.remove('hidden');
        this.iconTarget.classList.remove("fa-arrow-right-long");
        this.iconTarget.classList.add("fa-arrow-left-long");
        this.navbarSpanTarget.classList.add("w-[280px]");
        this.navbarSpanTarget.classList.remove("w-[90px]");
        this.topNavTarget.classList.add("pr-[280px]");
        this.topNavTarget.classList.remove("pr-[90px]");
      }
      else {
        this.fullNavbarTarget.classList.add('hidden');
        this.minNavbarTarget.classList.remove('hidden');
        this.iconTarget.classList.remove("fa-arrow-left-long");
        this.iconTarget.classList.add("fa-arrow-right-long");
        this.navbarSpanTarget.classList.add("w-[90px]");
        this.navbarSpanTarget.classList.remove("w-[280px]");
        this.topNavTarget.classList.add("pr-[90px]");
        this.topNavTarget.classList.remove("pr-[280px]");
      }
    } catch (error) {
      // Navbar not available here
    }
  }

  getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
    else {
      return "open";
    }
  }

  setCookie(name, value) {
    document.cookie = `${name}=${value}; path=/;`;
  }
}
