import { Controller } from 'stimulus';
import { get } from '@rails/request.js'

export default class extends Controller {
    static targets = ["requisitionSelect", "personSelect", "interviewersSelect", "intervieweeInput"] ;
    change(event) {
        let client_id = event.target.selectedOptions[0].value;
        let target = this.requisitionSelectTarget.id;
        let target2 = this.personSelectTarget.id;
        let target3 = this.interviewersSelectTarget.id;
		
        get(`/interviews/requisitions?target=${target}&client_id=${client_id}`, {
            responseKind: "turbo-stream"
        });
        get(`/interviews/persons?target=${target2}&requisition_id=nil&submission_id=nil`, {
            responseKind: "turbo-stream"
        });
        get(`/interviews/interviewers?target=${target3}&client_id=${client_id}`, {
            responseKind: "turbo-stream"
        });
    }

	requisitionChange(event) {
        let requisition_id = event.target.selectedOptions[0].value;
        let target = this.personSelectTarget.id;
		
        get(`/interviews/persons?target=${target}&requisition_id=${requisition_id}&submission_id=nil`, {
            responseKind: "turbo-stream"
        });
    }

    submissionChange(event) {
        let submission_id = event.target.selectedOptions[0].value;
        let target = this.personSelectTarget.id;

        get(`/interviews/persons?target=${target}&submission_id=${submission_id}&requisition_id=nil`, {
            responseKind: "turbo-stream"
        });
    }

    personChange(event) {
        let person_id = event.target.selectedOptions[0].value;
        console.log("Person Change Triggered, Person ID:", person_id);
        let target = this.intervieweeInputTarget.id;
        console.log("Interviewee Input Target ID:", target);

        get(`/interviews/interviewee?target=${target}&person_id=${person_id}`, {
            responseKind: "turbo-stream"
        });
    }
}