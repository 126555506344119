import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['editingButtons', 'notEditingButtons', 'editingFormDiv', 'notEditingFormDiv', 'editingForm'];

    connect() {
        this.showNotEditing();
    }

    toggleEditing(event) {
        event.preventDefault();
        this.editingButtonsTarget.classList.toggle('hidden');
        this.notEditingButtonsTarget.classList.toggle('hidden');
        this.editingFormDivTarget.classList.toggle('hidden');
        this.notEditingFormDivTarget.classList.toggle('hidden');
    }
    
    hideEditing() {
        this.showNotEditing();
    }
    
    showNotEditing() {
        this.editingButtonsTarget.classList.add('hidden');
        this.notEditingButtonsTarget.classList.remove('hidden');
        this.editingFormDivTarget.classList.add('hidden');
        this.notEditingFormDivTarget.classList.remove('hidden');
    }

    updateDateTime(event) {
        event.preventDefault();

        const dateInput = document.getElementById('interview_date');
        const timeInput = document.getElementById('interview_time');
        const timezoneInput = document.getElementById('interview_timezone');
        
        const datetimeString = `${dateInput.value} ${timeInput.value} ${timezoneInput.value}`;
        console.log(datetimeString);

        // Update the hidden field with the formatted datetime value
        this.element.querySelector('[data-interview-target="interviewAt"]').value = datetimeString;

        // Submit the form
        this.element.querySelector('form').submit();
    }
      
    timeZoneToOffset(timeZone) {
        // You can replace this function with your own logic to convert a time zone to its offset
        // This example assumes the time zone is in the format "+HH:MM" or "-HH:MM"
        const sign = timeZone.startsWith('-') ? -1 : 1;
        const [hours, minutes] = timeZone.split(':').map(Number);
        return (sign * (hours * 60 + minutes));
    }

    triggerSubmit() {
        this.updateDateTime(event);
    }

    change() {

    }
}