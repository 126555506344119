import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['month', 'year'];

  connect() {
    this.addEventListeners();
  }

  addEventListeners() {
    this.element.addEventListener('change', (event) => {
      event.preventDefault();
      this.submitForm();
    });

    // this.element.querySelector('#prev_month').addEventListener('click', (event) => {
    //   event.preventDefault();
    //   this.selectPreviousMonth();
    // });

    // this.element.querySelector('#next_month').addEventListener('click', (event) => {
    //   event.preventDefault();
    //   this.selectNextMonth();
    // });
  }

  submitForm() {
    const form = this.element;
    const submitButton = form.querySelector('[type="submit"]');

    setTimeout(() => {
      submitButton.click();
    }, 10);
  }

  selectPreviousMonth() {
    const selectedMonth = this.monthTarget.value;
    const selectedYear = this.yearTarget.value;
    const previousMonth = new Date(selectedYear, selectedMonth - 2, 1);
    this.monthTarget.value = previousMonth.getMonth() + 1;
    this.yearTarget.value = previousMonth.getFullYear();
    this.submitForm();
  }

  selectNextMonth() {
    const selectedMonth = this.monthTarget.value;
    const selectedYear = this.yearTarget.value;
    const nextMonth = new Date(selectedYear, selectedMonth, 1);
    this.monthTarget.value = nextMonth.getMonth() + 1;
    this.yearTarget.value = nextMonth.getFullYear();
    this.submitForm();
  }
}