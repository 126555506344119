import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["chipDiv", "chipTemplate", "skillInput", 
    "suggestDiv", "formSkillsInput", "submitButton", "formDiv"]

    suggestOptions = [];

    connect() {
        this.setInitialSkills();
        this.skillInputTarget.addEventListener("input", this.handleSkillInput.bind(this));
        this.skillInputTarget.addEventListener("keydown", this.handleEnterPress.bind(this));
        this.submitButtonTarget.addEventListener("click", this.handleSubmit.bind(this));
        this.setOptions();
    }

    setInitialSkills() {
        const init_skills = this.chipDivTarget.textContent;
        this.chipDivTarget.innerHTML = "";
        init_skills.split(",").forEach( skill => {
            if (skill !== "") {
                this.addSkill(skill.toLowerCase().charAt(0).toUpperCase() + skill.toLowerCase().slice(1));
            }
        });
        this.setVisibility(this.chipDivTarget, true);
    }

    handleEnterPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.skillInputTarget.value = this.skillInputTarget.value.trim() + ',';
            this.handleSkillInput(event);
        }
    }

    handleSkillInput(event) {
        const inputText = event.target.value.trim();
        const currentChoices = [];
        this.chipDivTarget.querySelectorAll("p").forEach((option) => {
            currentChoices.push(option.textContent);
        });

        if (inputText.includes(",")) {
            const skills = inputText.split(',').map(skill => skill.trim());
            skills.forEach(skill => {
                if (skill !== "") {
                    if (!currentChoices.some(choice => choice.toLowerCase() === skill.toLowerCase())) {
                        this.addSkill(skill);
                    }
                }
            });
            event.target.value = "";
            this.setVisibility(this.suggestDivTarget, false);
            return;
        }

        if (inputText.length > 0) {
            const relevantOptions = Array.from(this.suggestOptions).filter((option) => {
                return option.textContent.toLowerCase().includes(inputText.toLowerCase());
            });
            
            if (relevantOptions.length > 0) {
                this.suggestOptions.forEach((option) => {
                    this.setVisibility(option, false);
                });
                relevantOptions.forEach((option) => {
                    if (!currentChoices.some(choice => choice.toLowerCase() === option.textContent.toLowerCase())) {
                        this.setVisibility(option, true);
                    }
                });
                this.setVisibility(this.suggestDivTarget, true);
            } else {
                this.setVisibility(this.suggestDivTarget, false);
            }
        } else {
            this.setVisibility(this.suggestDivTarget, false)
        }
    }

    setVisibility(element, isVisible) {
        if (isVisible) {
            element.classList.remove("hidden");
        } else if (!isVisible && !element.classList.contains("hidden")) {
            element.classList.add("hidden");
        }
    }

    setOptions() {
        this.suggestOptions = this.suggestDivTarget.querySelectorAll("p");
        this.suggestOptions.forEach((option) => {
            option.addEventListener("click", () => {
                this.addSkill(option.textContent);
            });
        });
    }

    addSkill(chipText) {
        const currentSkills = Array.from(this.chipDivTarget.querySelectorAll("p")).map(skill => skill.textContent.toLowerCase());
        const lowerCaseChipText = chipText.toLowerCase();

        if (!currentSkills.includes(lowerCaseChipText)) {
            const chipTemplateContent = this.chipTemplateTarget.content.cloneNode(true);
            const pTag = chipTemplateContent.querySelector("p");
            pTag.textContent = chipText;

            const chipElement = chipTemplateContent.querySelector("span");
            const deleteIcon = chipElement.querySelector("i");
            deleteIcon.addEventListener("click", (e) => {
                e.stopPropagation();
                chipElement.remove();
            });

            this.chipDivTarget.appendChild(chipTemplateContent);

            this.skillInputTarget.value = "";
            this.setVisibility(this.suggestDivTarget, false);
        }
    }

    handleUncommitted(event) {
        const inputText = this.skillInputTarget.value.trim() + ",";
        const currentChoices = [];
        this.chipDivTarget.querySelectorAll("p").forEach((option) => {
            currentChoices.push(option.textContent);
        });
      
        if (inputText.endsWith(",")) {
          if (inputText.length > 1 && !currentChoices.some(choice => choice.toLowerCase() === inputText.slice(0, -1).trim().toLowerCase())) {
            this.addSkill(inputText.slice(0, -1).trim());
          }
          this.skillInputTarget.value = "";
          this.setVisibility(this.suggestDivTarget, false);
          return
        } 

        if (inputText.length > 0) {
            const relevantOptions = Array.from(this.suggestOptions).filter((option) => {
                return option.textContent.toLowerCase().includes(inputText.toLowerCase());
            });
            
            if (relevantOptions.length > 0) {
                this.suggestOptions.forEach((option) => {
                    this.setVisibility(option, false);
                });
                relevantOptions.forEach((option) => {
                    if (!currentChoices.some(choice => choice.toLowerCase() === option.textContent.toLowerCase())) {
                        this.setVisibility(option, true);
                    }
                });
                this.setVisibility(this.suggestDivTarget, true);
            } else {
                this.setVisibility(this.suggestDivTarget, false);
            }
        } else {
            this.setVisibility(this.suggestDivTarget, false)
        }
    }

    clearSkills(event) {
        event.stopPropagation();
        this.chipDivTarget.innerHTML = "";
    }

    handleSubmit(event) {
        event.preventDefault();

        this.handleUncommitted();

        this.formSkillsInputTarget.disabled = false;
        
        const currentChoices = [];
        this.chipDivTarget.querySelectorAll("p").forEach((option) => {
            currentChoices.push(option.textContent);
        });

        const skills = currentChoices.join(",").toLowerCase();

        this.formSkillsInputTarget.value = skills;

        // Trigger the form submit
        if(this.formDivTarget.checkValidity()) {
            this.formDivTarget.submit();
        } else {
            this.formDivTarget.reportValidity();
            // Disable It Again
            setTimeout(() => {
                this.formSkillsInputTarget.disabled = true;
            }, 5000);
        }
    }
}