import Dropzone from "dropzone";
import Cropper from "cropperjs";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
	getMetaValue,
	findElement,
	removeElement,
	insertAfter,
  } from "../helpers/dropzone";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    console.log("Connected to the dropzone controller");
    this.chipDiv = document.querySelector('.skill-div');
    if (this.chipDiv) {
        this.skillInput = document.querySelector('.skill-input');
        this.suggestDiv = document.querySelector('.suggestDiv');
        this.chipTemplate = document.querySelector('.chipTemplate');
    }
    const remainingResumes = parseInt(this.element.dataset.remainingResumes);
    if (remainingResumes) {
    console.log("Parsed Remaining Resumes:", remainingResumes);
    this.dropZone = createDropZone(this, remainingResumes);
    } else {
      this.dropZone = createDropZone(this)
    }
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false;
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  // For skills in requisition jd autofill
  setInitialSkills() {
    const init_skills = this.chipDiv.textContent;
    this.chipDiv.innerHTML = "";
    init_skills.split(",").forEach( skill => {
        if (skill !== "") {
            this.addSkill(skill.toLowerCase().charAt(0).toUpperCase() + skill.toLowerCase().slice(1));
        }
    });
    this.setVisibility(this.chipDiv, true);
  }

  handleEnterPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault(); 
        this.skillInput.value = this.skillInput.trim() + ',';
        this.handleSkillInput(event);
    }
  }

  handleSkillInput(event) {
    const inputText = event.target.value.trim();
    const currentChoices = [];
    this.chipDiv.querySelectorAll("p").forEach((option) => {
        currentChoices.push(option.textContent);
    });

    if (inputText.endsWith(",")) {
        if (inputText.length > 1 && !currentChoices.some(choice => choice.toLowerCase() === inputText.slice(0, -1).trim().toLowerCase())) {
            this.addSkill(inputText.slice(0, -1).trim());
        }
        event.target.value = "";
        this.setVisibility(this.suggestDiv, false);
        return
    } 

    if (inputText.length > 0) {
        const relevantOptions = Array.from(this.suggestOptions).filter((option) => {
            return option.textContent.toLowerCase().includes(inputText.toLowerCase());
        });

        if (relevantOptions.length > 0) {
            this.suggestOptions.forEach((option) => {
                this.setVisibility(option, false);
            });
            relevantOptions.forEach((option) => {
                if (!currentChoices.some(choice => choice.toLowerCase() === option.textContent.toLowerCase())) {
                    this.setVisibility(option, true);
                }
            });
            this.setVisibility(this.suggestDiv, true);
        } else {
            this.setVisibility(this.suggestDiv, false);
        }
    } else {
        this.setVisibility(this.suggestDiv, false)
    }
  }

  setVisibility(element, isVisible) {
    if (isVisible) {
        element.classList.remove("hidden");
    } else if (!isVisible && !element.classList.contains("hidden")) {
        element.classList.add("hidden");
    }
  }

  addSkill(chipText) {
    const chipTemplateContent = this.chipTemplate.content.cloneNode(true);
    const pTag = chipTemplateContent.querySelector("p");
    pTag.textContent = chipText;

    const chipElement = chipTemplateContent.querySelector("span");
    const deleteIcon = chipElement.querySelector("i");
    deleteIcon.addEventListener("click", (e) => {
        e.stopPropagation();
        chipElement.remove();
    });

    this.chipDiv.appendChild(chipTemplateContent);

    this.skillInput.value = "";
    this.setVisibility(this.suggestDiv, false);
  }

  // Resume Uploader
  uploadResume(file, target, url) {
    const form = new FormData();
    form.append("file", file);
    form.append("target", target);

    // Make a POST request to the URL
    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
      body: form
    })
      .then((response) => response.json())
      .then((data) => {
        const resumeLoadingSpan = document.getElementById(target);

        if (data.success) {
          if (data.redirect_url) {
            window.location.href = data.redirect_url;
          }
        }
        else {
            const resumeFailedTemplate = document.getElementById("resumeFailedTemplate");
            const clone = document.importNode(resumeFailedTemplate.content, true);
            clone.querySelector("p").textContent = `Failed to generate for ${file.name} because of ${data.errors}`;
            resumeLoadingSpan.innerHTML = clone.querySelector("span").innerHTML;
            console.log("Error", data.errors);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // JD Uploader
  uploadJD(file, target, url) {
    const form = new FormData();
    form.append("file", file);
    form.append("target", target);

    // Make a POST request to the URL
    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
      body: form
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const resumeLoadingSpan = document.getElementById(target);

        if (data.success) {
          // Autofill fields
          console.log("Description:", data.job_description)
          const jdDescription = document.querySelector("#requisition_jd");
          jdDescription.innerHTML = data.job_description;
          console.log("Title:", data.job_title)
          const jdTitle = document.querySelector("#requisition_title");
          jdTitle.value = data.job_title;
          const skillsString = data.skills.join(", ");
          console.log("Skills:", skillsString);
          this.chipDiv.textContent = data.skills;
          this.setInitialSkills();

          // Success UI
          const resumeSuccessTemplate = document.getElementById("resumeSuccessTemplate");
          const clone = document.importNode(resumeSuccessTemplate.content, true);
          clone.querySelector("p").innerHTML = `Successfully generated description from ${file.name}!`;
          resumeLoadingSpan.innerHTML = clone.querySelector("span").innerHTML;
        }
        else {
          const errorMessage = data.errors;
          console.error("Error:", errorMessage);
          const resumeFailedTemplate = document.getElementById("resumeFailedTemplate");
          const clone = document.importNode(resumeFailedTemplate.content, true);
          clone.querySelector("p").textContent = `Failed to generate description from ${file.name} because of ${data.errors}`;
          resumeLoadingSpan.innerHTML = clone.querySelector("span").innerHTML;
          console.log("Error", data.errors);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
          file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("success", file => {
      if (this.data.get("uploadType") === "resume") {
        const resumeLoadingDiv = document.getElementById("resumeLoadingDiv");
        const manualAdd = document.getElementById("manualAdd");
        if (manualAdd) {
          manualAdd.classList.add('hidden');
        }
        const resumeLoadingTemplate = document.getElementById("resumeLoadingTemplate");
        const clone = document.importNode(resumeLoadingTemplate.content, true);
        clone.querySelector("p").textContent = `Generating profile and resume for ${file.name}`;

        const uniqueId = `resumeLoading_${Date.now()}`;
        clone.querySelector("span").id = uniqueId;

        resumeLoadingDiv.appendChild(clone);

        const urlParams = new URLSearchParams(window.location.search);
        const engine = urlParams.get('engine');

        let url = '/resume/upload_resume';
        if (engine) {
          url += `?engine=${engine}`;
        }
        this.uploadResume(file, uniqueId, url);
      }
      else if (this.data.get("uploadType") === "job_description") {
        const resumeLoadingDiv = document.getElementById("resumeLoadingDiv");
        const resumeLoadingTemplate = document.getElementById("resumeLoadingTemplate");
        const clone = document.importNode(resumeLoadingTemplate.content, true);
        clone.querySelector("p").textContent = `Generating description from ${file.name}`;
        const uniqueId = `descriptionLoading_${Date.now()}`;
        clone.querySelector("span").id = uniqueId;

        resumeLoadingDiv.appendChild(clone);

        const url = '/process_file';

        this.uploadJD(file, uniqueId, url);
      }

      else if (this.data.has("uploadType") && this.data.get("uploadType").includes("resume-new")) {
        // Extract person id
        const person_id = this.data.get("uploadType").split("-")[2];

        const resumeLoadingDiv = document.getElementById("resumeLoadingDiv");
        const resumeLoadingTemplate = document.getElementById("resumeLoadingTemplate");
        const clone = document.importNode(resumeLoadingTemplate.content, true);
        clone.querySelector("p").textContent = `Building resume for ${file.name}`;

        const uniqueId = `resumeLoading_${Date.now()}`;
        clone.querySelector("span").id = uniqueId;

        resumeLoadingDiv.appendChild(clone);
        const urlParams = new URLSearchParams(window.location.search);
        const engine = urlParams.get('engine');

        let url = `/resume/upload_resume?person_id=${person_id}`;
        if (engine) {
          url += `&engine=${engine}`;
        }

        this.uploadResume(file, uniqueId, url);
      }
      else if (this.data.has("uploadType") && this.data.get("uploadType").includes("submissionperson-person")) {
        // Extract submission
        const submission_id = this.data.get("uploadType").split("-")[2]
        const resumeLoadingDiv = document.getElementById("resumeLoadingDiv");
        const resumeLoadingTemplate = document.getElementById("resumeLoadingTemplate");
        const clone = document.importNode(resumeLoadingTemplate.content, true);
        clone.querySelector("p").textContent = `Building resume for ${file.name}`;

        const uniqueId = `resumeLoading_${Date.now()}`;
        clone.querySelector("span").id = uniqueId;

        resumeLoadingDiv.appendChild(clone);
        const urlParams = new URLSearchParams(window.location.search);
        const engine = urlParams.get('engine');

        let url = `/self_register/upload_resume?submission_id=${submission_id}&submission_person=true`;
        if (engine) {
          url += `&engine=${engine}`;
        }

        this.uploadResume(file, uniqueId, url);
      }
      else if (this.data.has("uploadType") && this.data.get("uploadType").includes("submission-new")) {
        // Extract submissionperson id
        const submissionperson_id = this.data.get("uploadType").split("-")[2]
        const person_id = this.data.get("uploadType").split("-")[3]

        const resumeLoadingDiv = document.getElementById("resumeLoadingDiv");
        const resumeLoadingTemplate = document.getElementById("resumeLoadingTemplate");
        const clone = document.importNode(resumeLoadingTemplate.content, true);
        clone.querySelector("p").textContent = `Building resume for ${file.name}`;

        const uniqueId = `resumeLoading_${Date.now()}`;
        clone.querySelector("span").id = uniqueId;

        resumeLoadingDiv.appendChild(clone);
        const urlParams = new URLSearchParams(window.location.search);
        const engine = urlParams.get('engine');

        let url = `/resume/upload_resume?person_id=${person_id}&submission_person_id=${submissionperson_id}`;
        if (engine) {
          url += `&engine=${engine}`;
        }

        this.uploadResume(file, uniqueId, url);
      }
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get uploadType() {
    return this.data.get("uploadType") || "logo";
  }

  set setUploadType(newType) {
    this.data.set("uploadType", newType);
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller, remainingResumes) {
    var cachedName = "";
    return new Dropzone(controller.element, {
        url: controller.url,
        headers: controller.headers,
        maxFiles: (remainingResumes !== undefined && remainingResumes !== null) ? remainingResumes : controller.maxFiles,
        maxFilesize: controller.maxFileSize * 1024,
        acceptedFiles: controller.acceptedFiles,
        addRemoveLinks: controller.addRemoveLinks,
        thumbnailMethod: "contain",
        autoQueue: false,
        init: function () {
            this.on("complete", function (file) {
                if (file.status === "success") {
                    // Assuming you have a target class for the image_tag elements
                    const imageTagElements = document.querySelectorAll('.image-tag-class');

                    // Replace the src attribute of the image_tag elements
                    imageTagElements.forEach(function (element) {
                        element.src = file.previewElement.querySelector("img").src;
                    });
                }
            });

            this.on("addedfile", function(file) {
                if (this.files.length > this.options.maxFiles) {
                  this.removeFile(file);
                  alert(`Cannot upload the file "${file.name}". Maximum number of uploads reached.`);
                  return;
                }

                if (controller.uploadType === "logo" && file.name !== cachedName) {

                    // Remove existing editor if any
                    var existingEditor = document.getElementById("editor");
                    if (existingEditor) {
                        document.body.removeChild(existingEditor);
                    }

                    // Create Dropzone reference for use in confirm button click handler
                    var myDropZone = this;

                    // Create the editor div
                    var editor = document.createElement('div');
                    editor.id = "editor"; // Add an ID for easy reference
                    editor.style.position = 'fixed';
                    editor.style.left = 0;
                    editor.style.right = 0;
                    editor.style.top = 0;
                    editor.style.bottom = 0;
                    editor.style.zIndex = 9999;
                    editor.style.display = 'flex'; // Make it a flex container
                    editor.style.justifyContent = 'center'; // Center content horizontally
                    editor.style.alignItems = 'center'; // Center content vertically
                    editor.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';

                    // Create the centered div inside the editor
                    var contentDiv = document.createElement('div');
                    contentDiv.style.maxWidth = '832px';
                    contentDiv.style.width = '100%'; // Grow as much as possible
                    contentDiv.style.backgroundColor = '#FFFFFF'; // White
                    contentDiv.style.border = '1px solid #EEEEEE'; // Border
                    contentDiv.style.borderRadius = '10px 10px 10px 10px'; // Rounded top corners
                    editor.appendChild(contentDiv);

                    // Create the header div
                    var contentHeader = document.createElement('div');
                    contentHeader.style.width = '100%';
                    contentHeader.style.height = '84px';
                    contentHeader.style.backgroundColor = '#F9FAFB';
                    contentHeader.style.borderRadius = '10px 10px 0 0'; // Rounded top corners
                    contentHeader.style.fontSize = '24px';
                    contentHeader.style.fontWeight = '600';
                    contentHeader.style.padding = '2rem';
                    contentHeader.style.color = '#212B36';
                    contentHeader.innerHTML = 'Edit Photo';
                    contentDiv.appendChild(contentHeader);

                    // Create the cropper div
                    var cropperDiv = document.createElement('div');
                    cropperDiv.style.position = 'relative';
                    cropperDiv.style.overflow = 'hidden';
                    cropperDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    cropperDiv.style.margin = '0 auto';
                    contentDiv.appendChild(cropperDiv);

                    // Create the Confirm button at the bottom left of the white div
                    var buttonConfirm = document.createElement('button');
                    buttonConfirm.textContent = 'Confirm';

                    // Create an image node for Cropper.js
                    var image = new Image();
                    image.src = URL.createObjectURL(file);
                    image.onload = function() {
                      var maxWidth = 800; // maximum width
                      var minHeight = 300; // minimum height
                      var maxHeight = 400; // maximum height

                      // Calculate the width to maintain the original aspect ratio with a minimum height of 300px
                      var aspectRatio = image.width / image.height;
                      var limitedHeight = Math.min(maxHeight, Math.max(minHeight, image.height));
                      var limitedWidth = limitedHeight * aspectRatio;

                      // Limit the width if it exceeds maxWidth
                      if (limitedWidth > maxWidth) {
                          limitedWidth = maxWidth;
                          limitedHeight = limitedWidth / aspectRatio;
                      }

                      // Set cropperDiv width and height
                      cropperDiv.style.width = limitedWidth + 'px';
                      cropperDiv.style.height = limitedHeight + 'px';

                      cropperDiv.appendChild(image);

                        // Create Cropper.js
                        var cropper = new Cropper(image, {
                            aspectRatio: NaN,
                            zoomable: false,
                            movable: false
                        });

                        // Apply custom styles
                        buttonConfirm.style.border = 'none';
                        buttonConfirm.style.background = '#ed5e5e';
                        buttonConfirm.style.color = 'white';
                        buttonConfirm.style.borderRadius = '4px';
                        buttonConfirm.style.fontSize = '16px';
                        buttonConfirm.style.fontWeight = '500';
                        buttonConfirm.style.textAlign = 'center';
                        buttonConfirm.style.padding = '10px 25px';
                        buttonConfirm.style.cursor = 'pointer';
                        buttonConfirm.style.margin = '0.5rem 2rem';

                        contentDiv.appendChild(buttonConfirm);

                        buttonConfirm.addEventListener('click', function() {
                            // Get the canvas with image data from Cropper.js
                            var canvas = cropper.getCroppedCanvas({
                                maxWidth: 3072,
                                maxHeight: 3072,
                                imageSmoothingEnabled: 'false',
                                imageSmoothingQuality: 'high',
                            });
                            // Turn the canvas into a Blob (file object without a name)
                            canvas.toBlob(function(blob) {
                                // Create a new Dropzone file thumbnail
                                cachedName = file.name
                                blob.name = file.name;
                                setTimeout(() => {
                                    myDropZone.removeFile(file);
                                    myDropZone.addFile(blob);
                                }, 10);
                            }, 'image/png', 1);

                            // Remove the editor from the view
                            document.body.removeChild(editor);
                        });

                        document.body.appendChild(editor);
                    };
                }
            });

            this.on("removedfile", function (file) {
                if (file.status === "success") {
                    const imageTagElements = document.querySelectorAll('.image-tag-class');
                    imageTagElements.forEach(function (element) {
                        element.src = '';
                    });
                }
            });
        },
    });
}

