import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["textField"];

  connect() {
    console.log("Is this on?");
    // Initialize initialData with existing label_overrides data
    this.initialData = JSON.parse(this.element.dataset.labelOverridesInitial || "{}");
    this.hiddenField = document.querySelector(".label_overrides-jsonb");
    this.updateHiddenField();
  }

  handleChange(event) {
    const textField = event.target;
    const key = textField.dataset.key;
    const value = textField.value.trim();
    
    this.initialData[key] = value;
    this.updateHiddenField();
  }

  updateHiddenField() {
    this.hiddenField.value = JSON.stringify(this.initialData);
  }

  initialize() {
    this.textFieldTargets.forEach(textField => {
      textField.addEventListener("input", this.handleChange.bind(this));
    });
  }
}
