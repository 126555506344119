import { Controller } from 'stimulus';
import { get } from '@rails/request.js'

export default class extends Controller {
    static targets = ["requisitionSelect"] 
    change(event) {
        let client_id = event.target.selectedOptions[0].value;
        let target = this.requisitionSelectTarget.id
        get(`/contracts/requisitions?target=${target}&client_id=${client_id}`, {
            responseKind: "turbo-stream"
        })
    }
}