import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["checkbox", "button"];
  connect() {
    console.log("JS for Registration active");
    this.updateButtonState();
  }

  toggleRegistration(event) {
    const isChecked = event.target.checked;
    const submissionId = this.element.dataset.submissionId;

    if (!submissionId) {
      console.log("Checkbox not found.");
      return;
    }

    fetch(`/submissions/${submissionId}/update_registration`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ registration_active: isChecked })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.buttonTarget.disabled = !data.registration_active;
        this.updateButtonState();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  updateButtonState() {
    const button = this.buttonTarget;

    if (button) {
      if (button.disabled) {
        button.classList.remove("registration-active");
        button.classList.add("registration-inactive");
      } else {
        button.classList.remove("registration-inactive");
        button.classList.add("registration-active");
      }
    }
  }
}
