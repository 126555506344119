import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["formDiv2", "defBtn"]

    visibleForms;
    connect() {
        this.visibleForms = this.formDiv2Target.querySelectorAll("button.plus").length;
        setTimeout(this.setAddVisibility(), 10);
    }

    change() {
        setTimeout(() => this.setAddVisibility(), 10);
    }


    setAddVisibility() {
        const subForms = this.formDiv2Target.querySelectorAll(".nested-form-wrapper:not([style*='display: none'])");
        const plusButtons = [];
        subForms.forEach((subForm) => {
            const plusButton = subForm.querySelector("button.plus");
            if (plusButton) {
              plusButtons.push(plusButton);
            }
          });

        for (let i = 0; i < plusButtons.length; i++) {
          const button = plusButtons[i];
          
          // Check if it's the last button
          if (i === plusButtons.length - 1) {
            this.setVisibility(button, true);
          } else {
            this.setVisibility(button, false);
          }
        }

        let foundVisible = false;
        plusButtons.forEach((button) => {
          if(!button.classList.contains('hidden')) {
            foundVisible = true;
            return;
          }
        });

        this.setVisibility(this.defBtnTarget, !foundVisible);
    }

    setVisibility(element, isVisible) {
        if (isVisible) {
            element.classList.remove("hidden");
        } else if (!isVisible && !element.classList.contains("hidden")) {
            element.classList.add("hidden");
        }
    }

    handleMouseEvents(event) {
        console.log(event.type);
      }
}