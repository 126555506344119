import { Controller } from "stimulus";
import Pickr from "@simonwep/pickr";

function formatUrl(url) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url;
  }

  try {
    const uri = new URL(url);
    const hostname = uri.hostname;
    const pathname = uri.pathname;
    
    // Remove the trailing slash if it's present
    const formattedUrl = (pathname === '/' ? hostname : `${hostname}${pathname}`);
    
    return formattedUrl;
  } catch (error) {
    return 'Invalid URL';
  }
}


export default class extends Controller {
  static targets = ["picker", "input"];
  colorUpdateTimeout = null;

  initialize() {
    console.log('Controller initialized');

    this.initPicker();
    this.updateDynamicStyles();

	const company_name = document.querySelector('.input6');
	const company_url = document.querySelector('.input7');
	company_name.addEventListener('input', this.updateDynamicStyles.bind(this));
	company_url.addEventListener('input', this.updateDynamicStyles.bind(this));
  }  

  initPicker() {
    const initialColor = this.inputTarget.value || "#000000";

    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'classic',
      default: initialColor,

      components: {
        preview: true,
        opacity: true,
        hue: true,

        interaction: {
          hex: true,
          rgba: true,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: false,
        },
      },
    });

    this.picker.on('change', (color, _instance) => {
      this.inputTarget.value = color.toHEXA().toString();
      this.updateDynamicStyles();
      this.picker.applyColor(true);
    });

    this.inputTarget.addEventListener('input', () => {
      clearTimeout(this.colorUpdateTimeout);

      // Apply a delay before updating the color picker.
      this.colorUpdateTimeout = setTimeout(() => {
        const inputColor = this.inputTarget.value;
        this.picker.setColor(inputColor);
        this.updateDynamicStyles();
      }, 500);
    });

    // open the color picker when the text input is clicked
    this.inputTarget.addEventListener('click', () => {
      this.picker.show();
    });
  }

  updateDynamicStyles() {
    console.log('updateDynamicStyles called');
    const background_color = document.querySelector('.input1').value;
    const resume_header_text = document.querySelector('.input2').value;
    const portal_header_text = document.querySelector('.input3').value;
    const font_text_color = document.querySelector('.input4').value;
    const link_text_color = document.querySelector('.input5').value;
    const company_name = document.querySelector('.input6').value;
		const company_url_input = document.querySelector('.input7');
  
		// Determine the company_url based on input6 value
		let company_url = "your_url.com";
		if (company_url_input.value.trim() !== "") {
			company_url = formatUrl(company_url_input.value);
		}
    // Apply the styles to the dynamic elements
    const backgroundElements = document.querySelectorAll('.background-color');
    const fontTextElements = document.querySelectorAll('.font-text');
    const headerTextElement = document.querySelector('.header-text');
    const portalTextElement = document.querySelector('.portal-text');
    const linkTextElement = document.querySelector('.link-text');
    const buttonElement = document.querySelector('.button-color');
    const companyTextElement = document.querySelector('.logo-text');
  
    // Set the text color to the value from input2 or default to black if input2 is empty
    backgroundElements.forEach((element) => {
      element.style.backgroundColor = background_color || '#EFEFEF';
    });
    buttonElement.style.backgroundColor = link_text_color || 'black';
    fontTextElements.forEach((element) => {
      element.style.color = font_text_color || 'black';
    });
    portalTextElement.style.color = portal_header_text || '#212b36';
    headerTextElement.style.color = resume_header_text || 'black';
    linkTextElement.style.color = link_text_color || 'black';
    linkTextElement.textContent = company_url|| "your_url.com";

    if(companyTextElement) {
      companyTextElement.textContent = company_name || "Your Company";
      companyTextElement.style.color = portal_header_text || "Your Company";
    }
  }
}
