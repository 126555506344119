import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="populate-textarea"
export default class extends Controller {
  static targets = ["fromTextarea", "toTextarea"]

  connect() {
    console.log("Populate TextArea controller connected")
    this.fromTextareaTarget.addEventListener('input', this.updateTextArea.bind(this))
  }

  updateTextArea() {
    this.toTextareaTarget.value = this.fromTextareaTarget.value
  }
}
