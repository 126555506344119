import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["contentContainer", "stickyHeader"]

  connect() {
    this.adjustContentHeight()
    window.addEventListener('resize', this.adjustContentHeight.bind(this))
  }

  disconnect() {
    window.removeEventListener('resize', this.adjustContentHeight.bind(this))
  }

  adjustContentHeight() {
    const headerHeight = this.stickyHeaderTarget.offsetHeight
    const availableHeight = window.innerHeight - headerHeight
    this.contentContainerTarget.style.height = `${availableHeight}px`
  }
}
