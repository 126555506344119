import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="resume-uploader"
export default class extends Controller {
    static targets = ["dropzone"]

    connect() {
        const dropzone = new Dropzone(this.dropzoneTarget, {
            url: "/upload",
            success: (file, response) => {
                this.upload_resume(response); // Call your upload_resume function
            },
        });
    }
}
