import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="see-more-skills"
export default class extends Controller {
  static targets = ["show", "container", "icon"]

  connect() {
    this.seeMore = false;
    this.pTags = this.containerTarget.querySelectorAll(".toggle-hide");
    this.showTarget.addEventListener("click", this.toggle.bind(this));
  }

  toggle() {
    this.seeMore = !this.seeMore;

    if(this.seeMore) {
      this.pTags.forEach(pTag => {
        pTag.classList.remove("hidden");
      });
      this.iconTarget.classList.remove("fa-eye");
      this.iconTarget.classList.add("fa-eye-slash");
    } else {
      this.pTags.forEach(pTag => {
        pTag.classList.add("hidden");
      });
      this.iconTarget.classList.add("fa-eye");
      this.iconTarget.classList.remove("fa-eye-slash");
    }
  }
}
