import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hiddenSection", "visibleSection", "disableResumeCheckbox", "field"];

  connect() {
    console.log('Controller initialized');
    if (this.hasDisableResumeCheckboxTarget) {
      this.toggleSections();
      this.disableResumeCheckboxTarget.addEventListener('change', () => this.toggleSections());
    }
  }

  toggleSections() {
    if (!this.hasDisableResumeCheckboxTarget) return;

    const checkbox = this.disableResumeCheckboxTarget;
    const displayStyle = checkbox.checked ? "none" : "block";
    const oppositeDisplayStyle = checkbox.checked ? "block" : "none";

    if (this.hasHiddenSectionTarget) {
      this.hiddenSectionTargets.forEach(hiddenSection => {
        hiddenSection.style.display = displayStyle;

        const fields = hiddenSection.querySelectorAll('[data-hideresume-target="field"]');
        if (fields.length > 0) {
          fields.forEach(field => {
            field.required = !checkbox.checked;
          });
        }
      });
    }

    if (this.hasVisibleSectionTarget) {
      this.visibleSectionTargets.forEach(visibleSection => {
        visibleSection.style.display = oppositeDisplayStyle;

        const fields = visibleSection.querySelectorAll('[data-hideresume-target="field"]');
        if (fields.length > 0) {
          fields.forEach(field => {
            field.required = checkbox.checked;
          });
        }
      });
    }
  }
}
