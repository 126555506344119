import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// Connects to data-controller="notifbadge"
export default class extends Controller {
  static targets = ["badge"]
  connect() {
    this.subscription = consumer.subscriptions.create({ channel: "NotificationChannel" }, {
      connected: this._connected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  _connected() {
  }

  _received(data) {
    // If there is a new notification, unhide the badge
    this.badgeTarget.classList.remove("hidden");
  }
}
