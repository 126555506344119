import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["formDiv", "formDiv1", "formDiv3", "submitButton"]
    connect() {
        this.submitButtonTarget.addEventListener("click", this.handleSubmit.bind(this));
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setPosition(this.formDiv1Target.querySelectorAll(".nested-form-wrapper:not([style*='display: none'])"));
        this.setPosition(this.formDiv3Target.querySelectorAll(".nested-form-wrapper:not([style*='display: none'])"));
    }

    setPosition(div) {
        const positionInputs = [];

        div.forEach((subForm) => {
        const positionInput = subForm.querySelector(".position");
        if (positionInput) {
            positionInputs.push(positionInput);
        }
        });

        positionInputs.forEach((input, index) => {
            input.value = (index + 1).toString();
        });

        // Trigger the form submit
        if(this.formDivTarget.checkValidity()) {
            this.formDivTarget.submit();
        } else {
            this.formDivTarget.reportValidity();
        }       
    }
}