import { Controller } from 'stimulus';
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["modalContent"]

  connect() {
    console.log("Is this on?");
  }

  toggleModal(event) {
    const modalType = event.currentTarget.dataset.modalType;
    const interviewId = event.currentTarget.dataset.interviewId;
    const scheduleCount = event.currentTarget.dataset.scheduleCount;
    const modalContent = this.findModalContentByType(modalType);

    if (modalContent) {
      if (scheduleCount) {
        if (scheduleCount < 3) {
          event.preventDefault();
          const alertMessage = "Give at least 3 schedule blocks";
          this.flashAlert(alertMessage);
          return;
        }
      }
      const hiddenInput = document.querySelector('.modal_type_hidden');
      if (hiddenInput) {
        hiddenInput.value = modalType;
      }
      if (interviewId) {
        let target = "modal-show-interview";
        let url = `/interviews/interview?id=${interviewId}&target=${target}`
        console.log(url)

        get(`/interviews/interview?id=${interviewId}&target=${target}`, {
          responseKind: "turbo-stream"
        });
      }
      modalContent.classList.toggle("hidden");
    }
  }

  flashAlert(message) {
    const alertHTML = `
      <div class="notice notice-warning fixed bottom-5 right-4 max-w-[400px] items-center z-[1000]" role="alert" data-controller="notice-timer">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
        <p class="text-base">${message}</p>
      </div>
    `;

    const flashContainer = document.getElementById("flash");
    if (flashContainer) {
      flashContainer.innerHTML = alertHTML;
    }
  }

  reload(event) {
    const modalType = event.currentTarget.dataset.modalType;
    const modalContent = this.findModalContentByType(modalType);
    if (modalContent) {
      modalContent.classList.add("hidden");
      window.location.reload();
    }
  }

  findModalContentByType(modalType) {
    return this.modalContentTargets.find(content => content.dataset.modalType === modalType);
  }

  fillHiddenInput(event) {
    const button = event.currentTarget;
    const submissionPersonId = button.dataset.submissionPersonId;
    const submissionPersonName = button.dataset.submissionPersonName;
    const personEmail = button.dataset.personEmail;
    const noteId = button.dataset.noteId;
    const submissionPersons = document.querySelectorAll('.sp-person');
    const submissionPersonEmails = document.querySelectorAll('.interviewee-email');

    if (noteId) {
      console.log(noteId)
      get(`/interviews/note?target=modalSchedulingDiv&note_id=${noteId}`, {
        responseKind: "turbo-stream"
      });
    }
  
    submissionPersonEmails.forEach((submissionPersonEmail) => {
      if (submissionPersonEmail && submissionPersonEmail.value === "") {
        submissionPersonEmail.value = personEmail;
      }
    });
    submissionPersons.forEach(submissionPerson => {
      submissionPerson.textContent = submissionPersonName;
    });
    const hiddenInputs = document.querySelectorAll('.submission_person_hidden');
    hiddenInputs.forEach(hiddenInput => {
      hiddenInput.value = submissionPersonId;
    });
    this.toggleModal(event);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
