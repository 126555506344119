import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  update(event) {
    const form = this.element;
    const calendarValue = document.getElementById("calendar").value;

    // Remove any existing hidden input for selected_date
    const existingInput = form.querySelector("input[name='selected_date']");
    if (existingInput) {
      existingInput.remove();
    }

    // Create a hidden input to include the calendar value
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "selected_date";
    input.value = calendarValue;
    form.appendChild(input);

    // Submit the form
    form.requestSubmit();
  }
}
