import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="resume-builder"
export default class extends Controller {
  static targets = ["insert", "template"]

  connect() {
    console.log("ResumeBuilderController connected");
  }

  addInput() {
    const template = this.templateTarget.cloneNode(true);
    const newItem = template.content.querySelector("li");

    this.insertTarget.appendChild(newItem);

    const trixEditors = newItem.querySelectorAll(".trix-content");
    trixEditors.forEach(editor => {
      editor.innerHTML = "";
    });
  }

  deleteInput(event) {
    event.target.parentElement.remove();
  }

  disableSubmit(event) {
    console.log("disableSubmit called");
    const submitButton = this.element.querySelector('input[type="submit"]');
    const spinner = document.getElementById('spinner');

    if (submitButton) {
      submitButton.classList.add("button-inactive");
      spinner.classList.remove('hidden');
    }
  }
}