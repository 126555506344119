import { Controller } from 'stimulus';
import { get } from '@rails/request.js'
import SlimSelect from 'slim-select'

export default class extends Controller {
    static targets = ["requisitionSelect", "contactSelect", "personsDiv", "personSelect"]

    change(event) {
        let client_id = event.target.selectedOptions[0].value;
        let target1 = this.requisitionSelectTarget.id

        get(`/submissions/requisitions?target=${target1}&client_id=${client_id}`, {
            responseKind: "turbo-stream"
        })
        //let target2 = this.contactSelectTarget.id
        //get(`/submissions/contacts?target=${target2}&client_id=${client_id}`, {
        //    responseKind: "turbo-stream"
        //})
    }

    personChanged(event) {
      setTimeout(() => {
          var selectIds = [];
          var selectValues = [];
      
          this.personSelectTargets.forEach((select) => {
            selectIds.push(select.getAttribute('data-id'));
            selectValues.push(select.selectedOptions[0].textContent);
          });
      
          selectIds.forEach((id) => {
            let options = document.querySelectorAll(`[data-id="${id}"] .ss-option`);
            options.forEach((option) => {
              if (
                !option.classList.contains('ss-selected') &&
                option.textContent !== 'Select Person' &&
                selectValues.includes(option.textContent)
              ) {
                option.style.display = 'none';
              } else {
                option.style.display = '';
              }
            });
          });
        }, 10);
      
      const closestNestedForm = event.target.closest("div.nested-form-wrapper") 
      if (closestNestedForm) {
        const bill_input = closestNestedForm.querySelector("input[type='number']");
        const description_input = closestNestedForm.querySelector("input[type='hidden']");
        const description_trix = closestNestedForm.querySelector("trix-editor");
        //const interval_select = closestNestedForm.querySelector('select[name^="submission[submission_persons_attributes]"][name$="[interval]"]');

        fetch(`/persons/${event.target.options[event.target.selectedIndex].value}.json`)
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Get Person Error');
            }
          })
          .then(data => {
            bill_input.value = data.rate;
            description_input.value = data.description;
            description_trix.innerHTML = data.description;
            for (var i = 0; i < interval_select.options.length; i++) {
                if(interval_select.options[i].value === data.rate_interval) {
                    interval_select.slim.setSelected(data.rate_interval);
                    break;
                }
            }
          })
          .catch(error => {
            console.log("Error:", error);
          });
      }
    }
}
