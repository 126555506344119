import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "wrapper", "button"];

  connect() {
    this.collapsedHeight = 240;
    this.lineHeight = 24;
    this.expanded = false;
    this.seeMoreDiv = document.getElementById('see-more-div')
    if (this.seeMoreDiv) {
      this.viewFrom = this.seeMoreDiv.getAttribute('data-context');
    }

    requestAnimationFrame(() => this.checkHeight());

    window.addEventListener('resize', this.handleResize.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.checkHeight();
  }

  checkHeight() {
    if (!this.hasContentTarget || !this.hasWrapperTarget || !this.hasButtonTarget) {
      return;
    }

    const fullHeight = this.contentTarget.scrollHeight;
    if (fullHeight > this.collapsedHeight) {
      this.seeMoreDiv.style.display = 'flex';
      if (!this.expanded) {
        const adjustedHeight = this.getAdjustedHeight(this.collapsedHeight);
        this.wrapperTarget.style.maxHeight = `${adjustedHeight}px`;
        this.wrapperTarget.classList.add('collapsed');
      }
    } else {
      this.seeMoreDiv.style.display = 'none';
      this.wrapperTarget.classList.remove('collapsed');
      this.wrapperTarget.style.maxHeight = "none";
    }
  }

  getAdjustedHeight(height) {
    return Math.floor(height / this.lineHeight) * this.lineHeight;
  }

  toggle() {
    if (!this.hasWrapperTarget || !this.hasButtonTarget) {
      return;
    }

    if (this.expanded) {
      const adjustedHeight = this.getAdjustedHeight(this.collapsedHeight);
      this.wrapperTarget.style.maxHeight = `${adjustedHeight}px`;
      if (this.viewFrom === 'public-submission') {
        this.buttonTarget.textContent = "View full job description";
      } else {
        this.buttonTarget.textContent = "See More";
      }
      this.wrapperTarget.classList.add('collapsed');
    } else {
      this.wrapperTarget.style.maxHeight = "none";
      if (this.viewFrom === 'public-submission') {
        this.buttonTarget.textContent = "View less job description";
      } else {
        this.buttonTarget.textContent = "See Less";
      }
      this.wrapperTarget.classList.remove('collapsed');
    }
    this.expanded = !this.expanded;
  }
}
