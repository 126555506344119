import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="horizontal-scroll"
export default class extends Controller {
  static targets = ["container", "leftButton", "rightButton"];

  connect() {
    console.log("Horizontal Scroll");
    this.isDown = false;
    this.startX = 0;
    this.scrollLeft = 0;
    this.containerTarget.addEventListener('scroll', this.checkScrollButtons.bind(this)); // Add a scroll event listener to monitor scrolling dynamically
  }

  scrollLeftAction() {
    const scrollAmount = window.innerWidth * 0.4; 
    this.containerTarget.scrollLeft -= scrollAmount; // Adjust scroll amount
  }

  scrollRightAction() {
    const scrollAmount = window.innerWidth * 0.4; 
    this.containerTarget.scrollLeft += scrollAmount; // Adjust scroll amount
  }

  wheelScroll(event) {
    event.preventDefault();
    this.containerTarget.scrollLeft -= event.deltaY * 2;
    this.checkScrollButtons();
  }

  checkScrollButtons() {
    const scrollLeft = this.containerTarget.scrollLeft;
    const maxScrollLeft = this.containerTarget.scrollWidth - this.containerTarget.clientWidth;

    if (this.hasLeftButtonTarget) {
      if (scrollLeft <= 0) {
        this.leftButtonTarget.classList.add("hidden");
      } else {
        this.leftButtonTarget.classList.remove("hidden");
      }
    }

    if (this.hasRightButtonTarget) {
      if (scrollLeft >= maxScrollLeft - 1) {
        this.rightButtonTarget.classList.add("hidden");
      } else {
        this.rightButtonTarget.classList.remove("hidden");
      }
    }
  }
}