import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hiddenSection", "visibleSection", "disableToggleCheckbox"];

  connect() {
    console.log('Controller initialized');
    if (this.hasDisableToggleCheckboxTarget) {
      this.toggleSections();
    }
  }
  
  toggleSections() {
    const checkbox = this.disableToggleCheckboxTarget;

    if (checkbox) {
      this.hiddenSectionTargets.forEach((hiddenSection) => {
        this.toggleDisabled(hiddenSection, checkbox.checked);
      });

      this.visibleSectionTargets.forEach((visibleSection) => {
        this.toggleDisabled(visibleSection, !checkbox.checked);
      });
    }
  }

  toggleDisabled(section, isDisabled) {
    const fields = section.querySelectorAll('input, select, textarea');

    fields.forEach((field) => {
      field.disabled = isDisabled;
      field.setAttribute('required', !isDisabled);
      field.setAttribute('tabindex', isDisabled ? '-1' : '');
    });

    section.style.display = isDisabled ? 'none' : 'block';
  }
}
